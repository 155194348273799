<template>
	<div class="percent" :class="{dark:darkMode}">
		<svg height="46" width="46">
			<circle cx="23" cy="23" r="21" stroke="#FFE2E9" fill="transparent" stroke-width="2" class="circle" />
		</svg>
		<svg height="46" width="46">
			<path ref="progress" stroke-width="2" fill="transparent" stroke="#fff" class="progress" d="M 2, 23 a 21,21 0 1,0 42,0 a 21,21 0 1,0 -42,0" />
		</svg>
		{{ percent }}%
	</div>
</template>

<script>
import DrawSVGPlugin from '@/lib/gsap-bonus/DrawSVGPlugin'
import gsap from 'gsap/gsap-core'
gsap.registerPlugin(DrawSVGPlugin)

export default {
	name: 'PercentCircle',
	data () {
		return {
			percent: 70
		}
	},
	props: {
		darkMode: {
			type: Boolean,
			default: false
		}
	},
	mounted () {
		this.show()
	},
	methods: {
		show () {
			gsap.from(this.$el, { opacity: 0, y: 30 })
			gsap.fromTo(this.$refs.progress, { drawSVG: '100% 100%' }, { duration: 1, drawSVG: `100% ${100 - this.percent}%` })
		},
		hide () {
			gsap.to(this.$el, { opacity: 0, y: -20 })
		}
	}
}
</script>

<style lang="stylus" scoped>
	.percent
		width 46px
		height 46px
		font-family: 'Neue Montreal'
		font-style: normal
		font-weight: bold
		font-size: 12px
		text-align: center
		padding-top 15px
		color #fff
		position relative
		svg
			position absolute
			transform rotate(90deg)
			top 0
			left 0
			&.circle
				opacity 0.4
			&.progress
				opacity 0.6
		&.dark{
			.progress{
				stroke #FF5379
			}
			color #FF5379
			border-color: rgba(1, 1, 1, 0.25)
		}
</style>
