<template>
	<div class="LearnTile" :class="{lock:isLock,unlock:!isLock}">
		<div class="title" v-html="text" />
		<img src="img/ui/icon-lock.svg" class="locker" v-if="isLock">
		<img src="img/chapters/cloud.svg" class="cloud" ref="cloud" v-if="!isLock">
		<div v-if="!isLock" class="score">
			<img src="img/ui/icon-star.svg">120/130
		</div>
		<PercentCircle v-if="!isLock" ref="percent" :percent="70" />
		<img v-if="!isLock" :src="illustration" class="illustration">
		<div v-if="!isLock" class="details">
			<div class="titleLesson">
				The Packing
			</div>
			<div class="descLesson">
				Learn to read and write
			</div>
		</div>
	</div>
</template>

<script>
import { gsap, Quad } from 'gsap/all'
import PercentCircle from '@/components/PercentCircle.vue'
export default {
	name: 'LearnTile',
	components: {
		PercentCircle
	},
	props: {
		text: {
			type: String,
			default: ''
		},
		subtitle: {
			type: String,
			default: ''
		},
		illustration: {
			type: String,
			default: 'img/chapters/prelude.png'
		},
		desc: {
			type: String,
			default: ''
		},
		isLock: {
			type: Boolean,
			default: true
		}
	},
	mounted () {
		this.show()
	},
	methods: {
		show () {
			if (this.$refs.cloud) {
				gsap.fromTo(this.$refs.cloud, { y: 60, opacity: 0 }, { duration: 0.8, delay: 0.4, opacity: 1, y: 0 })
			}
		},
		hide () {
			if (this.$refs.cloud) {
				gsap.to(this.$refs.cloud, { y: -40, opacity: 0, duration: 0.7, ease: Quad.easeIn })
			}
		}
	}
}
</script>

<style lang="stylus" scoped>
.LearnTile
	padding 22px 20px 1px 20px
	user-select none
	margin-bottom 10px
	color #fff
	border-radius: 20px;
	position relative
	// overflow-x hidden
	.details
		position absolute
		bottom 20px
		left 20px
		text-align left
		.titleLesson
			font-family: 'NanumGothic';
			font-style: normal;
			font-weight: bold;
			font-size: 15px;
			line-height: 15px;
			letter-spacing: -0.02em;
			margin-bottom 10px
		.descLesson
			font-family: 'HK Grotesk Pro';
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 14px;
	.percent
		position absolute
		top 22px
		right 28px
	.score
		font-family: Neue Montreal;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		line-height: 27px;
		text-align left
		img
			margin-right 4px
	.illustration
		margin-left -20px
		margin-top -20px
		width calc(100% + 40px)
		// left 38px

	.cloud
		position absolute
		top 118px
		left -98px
		width 194px
		height auto
	.title
		font-family: NanumGothic;
		font-style: normal;
		font-weight: 800;
		font-size: 20px;
		line-height: 20px;
		letter-spacing: -0.02em;
		text-transform: uppercase;
		color: #FFFFFF;
		margin-top 10px
		text-align left
	.desc
		margin-top 20px
		font-family: 'HK Grotesk Pro';
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: 13px;
		letter-spacing: -0.03em;
		text-align: left;
	&.lock
		background #ffffff
		box-shadow: 0px 18px 63px rgba(249, 27, 91, 0.09), 0px 4.02054px 14.0719px rgba(249, 27, 91, 0.0536497), 0px 1.19702px 4.18956px rgba(249, 27, 91, 0.0363503);
		.title
			font-family: NanumGothic;
			font-style: normal;
			font-weight: bold;
			font-size: 15px;
			line-height: 24px;
			letter-spacing: -0.02em;
			color: #828282;
			text-transform capitalize
			margin-top 0
			margin-bottom 20px
			opacity: 0.6

		.locker
			position absolute
			right 38px
			top 18px
			width 23px
			height auto
			opacity: 0.6
	&.unlock
		background: linear-gradient(180deg, rgba(255, 83, 121, 0.5) 0%, #FF5379 100%);
		box-shadow: 0px 100px 80px rgba(255, 33, 123, 0.04), 0px 41.7776px 33.4221px rgba(255, 33, 123, 0.0503198), 0px 22.3363px 17.869px rgba(255, 33, 123, 0.0417275), 0px 12.5216px 10.0172px rgba(255, 33, 123, 0.035), 0px 6.6501px 5.32008px rgba(255, 33, 123, 0.0282725), 0px 2.76726px 2.21381px rgba(255, 33, 123, 0.0196802);

</style>
