<template>
	<div id="lessons">
		<BackButton ref="back" />
		<TextKR kr="프로필" size="small" ref="title">
			Learn
		</TextKR>
		<ProfileButton ref="profile" />
		<div class="tiles">
			<LearnTile
				v-for="chapter in chapters"
				:key="chapter.id"
				:text="chapter.title"
				:subtitle="chapter.subtitle"
				:percent="0"
				:is-lock="!isChapterUnlock(chapter.id)"
				:ref="setBtnRef"
				:data-id="chapter.id"
				@click="toChapter(chapter.id)"
			/>
		</div>
	</div>
</template>

<script>

import { gsap, Quad, TimelineMax } from 'gsap/all'
import router from '@/router'
import UserData from '@/data/UserData'
import ProfileButton from '@/components/ProfileButton.vue'
import TextKR from '@/components/TextKR.vue'
import LearnTile from '@/components/LearnTile.vue'

export default {
	name: 'Learn',
	components: {
		ProfileButton,
		TextKR,
		LearnTile
	},

	data () {
		return {
			btnRefs: [],
			btnRefs2: [],
			chapters: null
		}
	},

	mounted () {
		fetch('/data/chapters.json')
			.then((response) => response.json()).then((json) => {
				this.chapters = json
				this.$nextTick(this.transitionIn)
			})
	},

	methods: {
		setBtnRef (el) {
			if (el) {
				this.btnRefs.push(el.$el)
				this.btnRefs2.push(el)
			}
		},
		toChapter (id) {
			if (this.isChapterUnlock(id)) {
				router.push('/chapter/' + id)
			}
		},
		transitionIn () {
			const tl = new TimelineMax({ delay: 0.3 })
			tl.staggerFromTo(this.btnRefs, 0.5, { opacity: 0, y: 100 }, { opacity: 1, y: 0, ease: Quad.easeOut, duration: 1.1 }, 0.1, 0,
				this.checkChapterCompleted)
		},
		transitionOut () {
			this.$refs.back.hide()
			this.$refs.title.hide()
			this.$refs.profile.hide()
			for (const btn of this.btnRefs2) {
				btn.hide()
			}
			const tl = new TimelineMax({ delay: 0.1 })
			tl.staggerTo(this.btnRefs, 0.5, { opacity: 0, y: -80, ease: Quad.easeOut }, 0.1, '+=0')
		},
		checkChapterCompleted () {
			for (let i = 0; i < this.chapters.length; i++) {
				const chapter = this.chapters[i]
				if (chapter.id == UserData.lastChapterFinish.value) {
					if (i == this.chapters.length - 1) {
						console.log('application completed!')
					} else {
						console.log('chapter completed!')
						if (UserData.unlockChapter(this.chapters[i + 1].id)) {
							for (const btn2 of this.btnRefs) {
								if (btn2.dataset.id == this.chapters[i + 1].id) {
									gsap.to(btn2, { scale: 1.1, ease: 'scaleEase', duration: 0.6 })
								}
							}
						}
					}
				}
			}
		},

		isChapterUnlock (id) {
			return UserData.isChapterUnlock(id)
		}
	},
	beforeUpdate () {
		this.btnRefs = []
	},
	beforeRouteLeave (to, from, next) {
		this.transitionOut()
		setTimeout(next, 1200)
	}
}
</script>
<style lang="stylus" scoped>
p
	color #fff
	font-size 24px
	max-width 80%
	margin auto
	margin-bottom 40px
.tiles
	padding 0 35px
	margin-top 60px
</style>
